<script>
import axios from 'axios'

export default {
  name: 'SkinCheckResult',
  data(){
    return {
      skin_type_id: "0",
      id_token: "",
      result_set: "",
      image_source: '',
      ROOT_IMAGE_URL: process.env.VUE_APP_IMAGE_ROOT,
      isInViewport: false
    }
  },
  created(){
    // 一時的な対応
    // 本来はNull check等、前ページからデータをちゃんと引き継いでいるかチェックすべし
    this.skin_type_id = history.state.skin_type_id
    this.id_token = history.state.id_token
    if(typeof history.state.result_set == "undefined"){
      this.result_set = ""
    }else{
      this.result_set = history.state.result_set
    }
    const target_url = process.env.VUE_APP_RESULT_URL+'skin-type-diag';
    axios.post(target_url, {
      liff_id: process.env.VUE_APP_LIFF_ID,
      command: 'get_result_images',
      skin_type_id: this.skin_type_id,
      id_token: this.id_token,
      result_set: this.result_set
    })
      .then((res) => {
        if(res.data.is_first_diag){
          this.$gtag.event('tutorial_complete')
        }
        this.image_source = process.env.VUE_APP_BASE_URL+`flow-images/skin-check/result-images/${res.data.resultNumber}.png`;
      })
      .catch((err) => {
        console.log('SkinCheckResult',err.code, err.message);
      })
  },
  mounted(){
    const observer = new IntersectionObserver(
    (entries) => {
      console.log(entries)
      const firstEntry = entries[0];
      if (firstEntry.isIntersecting) {
        this.isInViewport = true
      }
    },
    {
      root: document.querySelector("skincheck_result_inner"),
      threshold: 0,
      rootMargin: "40px",
    }
  );
  let target = document.querySelector('#arrow2');
  observer.observe(target);
  }
};
</script>

<template>
  <div>
    <div class="skincheck_result_inner">
      <img
        :src="image_source"
      >
    </div>
    <section class="dermatologist_inCarge_container">
      <h2>{{ $t('flowSkinCheckSupervisor') }}</h2>
      <div class="dermatologist_inCarge_inner">
        <div class="dermatologist_inCarge_inner--separate">
          <img
            :src="
              ROOT_IMAGE_URL
                + '/face-icon/hamano.png'
            "
          >
        </div>
        <div class="dermatologist_inCarge_inner--separate">
          <h3>{{ $t('hamanoName') }}</h3>
          <div class="dermatologist_profile">
            <p>{{ $t('hamanoTitle') }}</p>
            <p>{{ $t('hamanoAffiliatedEx') }}</p>
            <p>{{ $t('hamanoAffiliated6') }}</p>
          </div>
          <div class="profile_btn_container">
            <router-link
              :to="{
                name: 'Concierges',
                params: {'incomingGenre': 'skincheck'}
              }"
              class="profile_btn"
            >
              {{ $t('conciergeSeeProfile') }}
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="guiding_section_container">
      <Transition name="fadeInArrow">
        <div
          v-show="isInViewport"
          class="arrow_c_1"
        >
          <span></span>
        </div>
      </Transition>
      <div class="arrow_c_2" id="arrow2">
        <span></span>
      </div>
      <div class="item-suggestions_container">
        <img
          src="@/assets/about/karte.png"
          class="item-suggestions_img"
        >
        <div class="item-suggestions_contents_inner">
          <div class="item-suggestions_contents skincheck_btn_done">
            <div class="item-suggestions_contents_imgBox">
              <img src="@/assets/logos/skin_check.svg" class="skinCheck_btn_img">
            </div>
            <p class="item-suggestions_btn_title">
              {{ $t('categorySkinCheck') }}
            </p>
          </div>
          <img src="@/assets/logos/check.png" class="check_icon">
          <router-link to="/flow/item-suggestions" class="skinkcare_btn_link_container">
            <div class="item-suggestions_contents skincare_btn_link">
              <div class="item-suggestions_contents_imgBox item-suggestions_btn_imgBox">
                <img src="@/assets/logos/skincare_suggestion.svg">
              </div>
              <p class="item-suggestions_btn_title item-suggestions_btn">
                {{ $t('resultSkinCheckToSkincareSuggestions') }}<img src="@/assets/about/arrow.png">
              </p>
            </div>
          </router-link>
          <div class="item-suggestions_contents">
            <div class="item-suggestions_contents_imgBox">
              <img src="@/assets/logos/makeup_suggestion.svg">
            </div>
            <p class="item-suggestions_btn_title">{{ $t('resultSkinCheckToMakeupSuggestions') }}</p>
          </div>
          <div class="item-suggestions_contents">
            <div class="item-suggestions_contents_imgBox">
              <img src="@/assets/logos/ingredientcheck_icon.svg">
            </div>
            <p class="item-suggestions_btn_title">{{ $t('resultSkinCheckToIngredientCheck') }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  margin: 0;
}
h2 {
  font-size: 22px;
}
h3 {
  font-size: 20px;
  color: #222;
}
p {
  font-size: 14px;
}
.skincheck_result_inner {
  min-height: 1200px;
}
.dermatologist_inCarge_container {
  background-color: #F5EAE7;
  padding: 100px 15px 150px 15px;
}
.dermatologist_inCarge_container h2 {
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
}
.dermatologist_inCarge_inner {
  display: flex;
  align-items: center;
}
.dermatologist_inCarge_inner--separate {
  margin: 0 auto;
}
.dermatologist_inCarge_inner--separate img {
  width: 140px;
  border-radius: 50%;
  border: 1px solid #ccc;
}
.dermatologist_inCarge_inner--separate h3 {
  text-align: center;
}
.dermatologist_profile {
  padding: 10px 0;
}
.dermatologist_profile p {
  font-size: 12px;
  padding-bottom: 5px;
}
.dermatologist_profile p:last-child {
  font-size: 12px;
  padding-bottom: 0;
}
.profile_btn_container {
  text-align: center;
}
.profile_btn {
  color: #777;
  background-color: #FADAD2;
  padding: 10px;
  font-size: 14px;
  display: inline-block;
}
.guiding_section_container{
  background-color: #F5EAE7;
  padding-bottom: 50px;
}
.arrow_c_1{
  position: relative;
  padding-bottom: 30px;
}
.arrow_c_1 span{
  display: block;
	vertical-align: middle;
	position: absolute;
  left: 50%;
}
.arrow_c_1 span::before, .arrow_c_1 span::after{
  content: "";
	display: block;
	width: 30vw;
  max-width: 124px;
	height: 5px;
	background-color: #E6A393;
	position: absolute;
	right: 0;
}
.arrow_c_1 span::before{
  transform: rotate(15deg);
  transform-origin: right center;
  top: 33%;
}
.arrow_c_1 span::after{
  transform: rotate(165deg);
  transform-origin: right center;
  top: -33%;
  right: 1px;
}
/* フェードインアニメーション */
.fadeInArrow-enter-from{
  opacity: 0;
}
.fadeInArrow-enter-active{
  transition: opacity 0.5s ease
}
.fadeInArrow-enter-to{
  opacity: 1;
}
.arrow_c_2{
  position: relative;
  padding-bottom: 150px;
}
.arrow_c_2 span{
  display: block;
	vertical-align: middle;
	position: absolute;
  left: 50%;
}
.arrow_c_2 span::before, .arrow_c_2 span::after{
  content: "";
	display: block;
	width: 30vw;
  max-width: 124px;
	height: 5px;
	background-color: #E6A393;
	position: absolute;
	right: 0;
}
.arrow_c_2 span::before{
  transform: rotate(15deg);
  transform-origin: right center;
  top: 33%;
}
.arrow_c_2 span::after{
  transform: rotate(165deg);
  transform-origin: right center;
  top: -33%;
  right: 1px;
}
.item-suggestions_container{
  position: relative;
  background-color: #F5EAE7;
}
.item-suggestions_img{
  width: 100vw;
  max-width: 414px;
}
.item-suggestions_contents_inner{
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 24%;
  width: 80%;
  margin: 0 auto;
  right: 0;
  left: 0;
  align-items: center;
}
.item-suggestions_contents{
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  width: 100%;
  padding: 15px;
  opacity: 0.3;
}
.skincheck_btn_done{
  opacity: 0.3;
  width: 90%;
  border: 1px solid #777;
  border-radius: 20px;
  box-shadow: 3px 3px 3px rgb(0 0 0 / 20%);
  margin-bottom: 20px;
}
.skinkcare_btn_link_container{
  width: 90%;
}
.skincare_btn_link{
  opacity: 1;
  border: 3px solid #E6A393;
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 15px;
  animation: blinkEffect 1s ease infinite alternate;
}
@keyframes blinkEffect {
  0% {
    box-shadow: none;
    opacity: 0.7;
  }
  100% {
    box-shadow: 3px 3px 3px rgb(0 0 0 / 20%);
    opacity: 1;
  }
}
.item-suggestions_contents_imgBox{
  width: 30%;
  text-align: center;
}
.item-suggestions_btn_imgBox{
  width: 20%;
}
.item-suggestions_contents_imgBox img{
  width: 40px;
}
.item-suggestions_btn_title{
  width: 70%;
  text-align: center; 
  font-size: 20px;
}
.item-suggestions_btn_title img{
  width: 34px;
  padding: 5px;
}
.item-suggestions_btn{
  font-weight: bold;
  font-size: 21px;
  width: 80%;
  padding-top: 3px;
}
.check_icon{
  position: absolute;
  width: 60px;
  left: 15px; 
}
.guiding_section_inner {
  padding: 100px 40px 0;
  text-align: center;
  background-color: #F5EAE7;
}
.guiding_arrow{
  width: 26px;
  padding-bottom: 40px;
}
img {
  width: 100%;
  object-fit: cover;
  border: #F29F8F;
}
img, embed, object, iframe {
  vertical-align: bottom;
}
a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
router-link {
  background-color: transparent;
  text-decoration: none;
  color: inherit; 
}
.guiding_section_toChatbot a {
  display: block;
  padding: 20px 30px;
  font-size: 18px;
  color: #444;
  background-color: #fadad2;
  font-weight: bold;
}
.guiding_section_toChatbot {
  padding-bottom: 50px;
}
.guiding_section_toChatbot p {
  display: block;
  padding-bottom: 20px;
  font-size: 16px;
}
</style>
